import React, { useState, useEffect } from "react";

const PaginatedList = ({ fetchUrl, columns, title }) => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(10); // Items per page
  const [totalPages, setTotalPages] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const fetchData = async () => {
    const offset = (page - 1) * limit;
    try {
      const response = await fetch(`${fetchUrl}?offset=${offset}&limit=${limit}`);
      const responseData = await response.json();
      if (Array.isArray(responseData)) {
        setData(responseData);
        setFilteredData(responseData);
        setTotalPages(Math.ceil(responseData.length / limit)); // Adjust this if API provides total count
      }
    } catch (error) {
      console.error(`Error fetching data from ${fetchUrl}:`, error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, fetchUrl]);

  // Filter data by startDate and endDate
  useEffect(() => {
    if (startDate || endDate) {
      let filtered = data;

      if (startDate) {
        filtered = filtered.filter(
          (item) => new Date(item.created_at) >= new Date(startDate)
        );
      }
      if (endDate) {
        filtered = filtered.filter(
          (item) => new Date(item.created_at) <= new Date(endDate)
        );
      }

      setFilteredData(filtered);
    } else {
      setFilteredData(data); // Reset if no filters
    }
  }, [startDate, endDate, data]);

  const handleNext = () => {
    if (page < totalPages) setPage((prev) => prev + 1);
  };

  const handlePrevious = () => {
    if (page > 1) setPage((prev) => prev - 1);
  };

  return (
    <>
    <div className="container">
      <h2>{title}</h2>

      {/* Filters */}
      <div className="filters mb-4">
        <div className="row">
          <div className="col-md-6"></div>
          <div className="col-md-3">
            <label>Start Date:</label>
            <input
              type="date"
              className="form-control"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div className="col-md-3">
            <label>End Date:</label>
            <input
              type="date"
              className="form-control"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>

        </div>
      </div>

      {/* Data Table */}
      {filteredData.length > 0 ? (
        <table className="table table-striped">
          <thead className="table-dark">
            <tr>
              {columns.map((col) => (
                <th key={col.key}>{col.label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item) => (
              <tr key={item.id || item.leadId}>
                {columns.map((col) => (
                  <td key={col.key}>{col.render ? col.render(item) : item[col.key]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p style={{
              background:"black",
              color:"#fff",
              padding:"10px",
        }}>No data available.</p>
      )}

      {/* Pagination */}
      <div className="pagination">
        <button
          className="btn btn-primary me-2"
          onClick={handlePrevious}
          disabled={page === 1}
        >
          Previous
        </button>
        <span>
          Page {page} of {totalPages}
        </span>
        <button
          className="btn btn-primary ms-2"
          onClick={handleNext}
          disabled={page === totalPages}
        >
          Next
        </button>
      </div>
    </div>
    </>
  );
};

export default PaginatedList;
