import React, { useState } from "react";
import PaginatedList from "./PaginatedList";

const CustomerList = () => {
  // Setting Customer Form data
  const [customerFormData, setCustomerFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    gstin: "",
  });

  // Errors state for validation
  const [errors, setErrors] = useState({});

  // Handle input change
  const handleCustomerFormChange = (e) => {
    const { name, value } = e.target;
    setCustomerFormData({
      ...customerFormData,
      [name]: value,
    });
    setErrors({ ...errors, [name]: "" }); // Clear error for the field
  };

  // Validate form
  const validateForm = () => {
    const newErrors = {};
    if (!customerFormData.name) newErrors.name = "Customer Name is required";
    if (!customerFormData.email) newErrors.email = "Email is required";
    if (!customerFormData.phone) newErrors.phone = "Phone is required";
    if (!customerFormData.gstin) newErrors.gstin = "GST No is required";
    if (!customerFormData.address) newErrors.address = "Address is required";
    return newErrors;
  };

  // Handle Add Customer
  const handleAddCustomer = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const response = await fetch(
        "https://standskills.co.in/riddhimaagency/public/api/customers",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(customerFormData),
        }
      );

      if (response.ok) {
        alert("Customer added successfully!");
        setCustomerFormData({
          name: "",
          email: "",
          phone: "",
          address: "",
          gstin: "",
        });
        setErrors({});
        // Optionally, you can refresh the customer list:
        // fetchCustomers();
      } else {
        alert("Failed to add customer.");
      }
    } catch (error) {
      console.error("Error adding customer:", error);
    }
  };

  // Edit functionality
  const [selectedItem, setSelectedItem] = useState(null);

  const handleGetCustomer = (item) => {
    setSelectedItem(item);
    setCustomerFormData({
      id: item.id || "",
      name: item.name || "",
      email: item.email || "",
      phone: item.phone || "",
      gstin: item.gstin || "",
      address: item.address || "",
    });
  };

  return (
    <>
      <PaginatedList
        fetchUrl="https://standskills.co.in/riddhimaagency/public/api/customers"
        columns={[
          { key: "name", label: "Customer Name" },
          { key: "email", label: "Email" },
          { key: "phone", label: "Phone" },
          { key: "gstin", label: "GSTIN" },
          { key: "address", label: "Address" },
          {
            key: "created_at",
            label: "Actions",
            render: (item) => (
              <div className="action-buttons">
                <button
                  className="btn btn-warning btn-sm me-2"
                  onClick={() => handleGetCustomer(item)}
                >
                  <i className="bi bi-pencil-square"></i>
                </button>
                <button className="btn btn-danger btn-sm me-2">
                  <i className="bi bi-trash3-fill"></i>
                </button>
              </div>
            ),
          },
        ]}
        title="Customer List"
      />
      <div className="drawer bg-light border-left position-fixed end-0 top-0 h-100 p-4">
        <form onSubmit={handleAddCustomer}>
          {[
            { name: "name", label: "Customer Name" },
            { name: "email", label: "Email" },
            { name: "phone", label: "Phone" },
            { name: "gstin", label: "GST No" },
            { name: "address", label: "Address" },
          ].map((field) => (
            <div key={field.name} className="mb-3">
              <label htmlFor={field.name} className="form-label">
                {field.label}
              </label>
              <input
                type="text"
                name={field.name}
                className={`form-control ${
                  errors[field.name] ? "is-invalid" : ""
                }`}
                onChange={handleCustomerFormChange}
                value={customerFormData[field.name] || ""}
              />
              {errors[field.name] && (
                <div className="invalid-feedback">{errors[field.name]}</div>
              )}
            </div>
          ))}
          <button type="submit" className="btn btn-primary">
            Save & Print
          </button>
        </form>
      </div>
    </>
  );
};

export default CustomerList;
