import React, { useState, useEffect } from 'react';
import Select from 'react-select';

const ProductDropdown = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  // Fetch products from the API
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch('https://standskills.co.in/riddhimaagency/public/api/products');
        if (!response.ok) {
          throw new Error('Failed to fetch products');
        }
        const data = await response.json();
        setProducts(data);  // Assuming the API returns the product data directly in the response body
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  // Format data for react-select
  const productOptions = products.map(product => ({
    value: product.id,   // Use the appropriate field for unique value
    label: product.name  // Use the appropriate field for the label
  }));

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }


  // Handle the selection of a product
  const handleProductChange = (selectedOption) => {
    const product = products.find(p => p.id === selectedOption.value);
    setSelectedProduct(product);  // Store the selected product's details
  };
  return (
    <>
      <Select 
        options={productOptions}
        onChange={handleProductChange}  // Handle product selection
      />
      
      {selectedProduct && (
        <div>
          <h4>Product Details</h4>
          <p><strong>MRP:</strong> {selectedProduct.mrp}</p>
          <p><strong>Rate:</strong> {selectedProduct.rate}</p>
          <p><strong>GST Rate:</strong> {selectedProduct.gst_rate}</p>
          <p><strong>HSN Code:</strong> {selectedProduct.hsn_code}</p>
        </div>
      )}
    </>
  );
};

export default ProductDropdown;
