import React, { useState } from "react";
import PaginatedList from "./PaginatedList";

const ProductList = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  // Setting Product Form data
  const [productFromData, setProductFromData] = useState({
    name: "",
    hsn_code: "",
    mrp: "",
    gst_rate: "",
    rate: "",
    reorder_level: "",
  });

  // Errors state for validation
  const [errors, setErrors] = useState({});

  // Handle input change
  const handleProductFormChange = (e) => {
    const { name, value } = e.target;
    setProductFromData({
      ...productFromData,
      [name]: value,
    });
    setErrors({ ...errors, [name]: "" }); // Clear error for the field
  };

  // Validate form
  const validateForm = () => {
    const newErrors = {};
    if (!productFromData.name) newErrors.name = "Product Name is required";
    if (!productFromData.hsn_code) newErrors.hsn_code = "HSN Code is required";
    if (!productFromData.mrp) newErrors.mrp = "MRP is required";
    if (!productFromData.gst_rate) newErrors.gst_rate = "GST Rate is required";
    if (!productFromData.rate) newErrors.rate = "Rate is required";
    if (!productFromData.reorder_level)
      newErrors.reorder_level = "Reorder Level is required";
    return newErrors;
  };

 
  // Handle Add Product
  const handleAddProduct = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const response = await fetch(
        "https://standskills.co.in/riddhimaagency/public/api/products",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(productFromData),
        }
      );

      if (response.ok) {
        alert("Product added successfully!");
        //fetchProducts();
        setProductFromData({
          name: "",
          hsn_code: "",
          mrp: "",
          gst_rate: "",
          rate: "",
          reorder_level: "",
        });
        setErrors({});
      } else {
        alert("Failed to add product.");
      }
    } catch (error) {
      console.error("Error adding product:", error);
    }
  };

//Edit 
const [selectedItem, setSelectedItem] = useState(null);
const handleGetProduct = (item) => {
    setSelectedItem(item);
    setProductFromData({
      ...productFromData,
      id: item.id || "",
      name: item.name || "",
      id: item.id || "",
      hsn_code: item.hsn_code || "",
      gst_rate: item.gst_rate || "",
      rate: item.rate || "",
      reorder_level: item.reorder_level || "",
      reorder_level: item.reorder_level || "",
    });
}
  return (
    <>
      <PaginatedList
        fetchUrl="https://standskills.co.in/riddhimaagency/public/api/products"
        columns={[
          { key: "name", label: "Product Name" },
          { key: "hsn_code", label: "HSN Code" },
          { key: "gst_rate", label: "Gst %" },
          { key: "mrp", label: "Price" },
          { key: "rate", label: "Rate" },
          { key: "reorder_level", label: "Reorder Level" },
          { key: "reorder_level", label: "Stock" },
          {
            key: "created_at",
            label: "Created At",
            render: (item) => (
                <div className="action-buttons">
                {/* <button className="btn btn-success btn-sm me-2"><i class="bi bi-plus-circle"></i></button> */}
                <button className="btn btn-warning btn-sm me-2" onClick={() => handleGetProduct(item)}><i class="bi bi-pencil-square"></i></button>
                <button className="btn btn-danger btn-sm me-2"><i class="bi bi-trash3-fill"></i></button>
                </div>
            ),
            },
        ]}
        title="Product List"
      />
      <button className="btn btn-primary toggle-drawer-btn" onClick={() => setIsDrawerOpen(!isDrawerOpen)} style={{position:"fixed",right:"10px",top:"10px",}}>
  {isDrawerOpen ? "Close" : "Add Product"}
</button>
      
      <div className="drawer bg-light border-left position-fixed end-0 top-0 h-100 p-4" style={{ width: isDrawerOpen ? "250px" : "0", transition: "width 0.3s" }}>
        <form onSubmit={handleAddProduct}>
          {[
            { name: "name", label: "Product Name" },
            { name: "hsn_code", label: "HSN Code" },
            { name: "mrp", label: "MRP" },
            { name: "gst_rate", label: "GST Rate" },
            { name: "rate", label: "Rate" },
            { name: "reorder_level", label: "Reorder Level" },
          ].map((field) => (
            <div key={field.name} className="mb-3">
              <label htmlFor={field.name} className="form-label">
                {field.label}
              </label>
              <input
                type="text"
                name={field.name}
                className={`form-control ${
                  errors[field.name] ? "is-invalid" : ""
                }`}
                onChange={handleProductFormChange}
                value={productFromData[field.name]}
              />
              {errors[field.name] && (
                <div className="invalid-feedback">{errors[field.name]}</div>
              )}
            </div>
          ))}
          <button type="submit" className="btn btn-primary">
            Save
          </button>
        </form>
      </div>
    </>
  );
};

export default ProductList;
