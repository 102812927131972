import React from 'react';
import CustomerSelect from '../includes/CustomerSelect';
import ProductRow from '../includes/ProductRow';

const InvoiceForm = ({
  invoiceFromData,
  customerOptions,
  productOptions,
  handleCustomerChange,
  handleProductChange,
  handleItemChange,
  addItem,
  removeItem,
  handleInvoiceFormChange,
  handleAddInvoice,
  errors,
}) => (
  <form onSubmit={handleAddInvoice}>
    {/* Customer Selection */}
    <CustomerSelect
      options={customerOptions}
      value={customerOptions.find((option) => option.value === invoiceFromData.customer_id)}
      onChange={handleCustomerChange}
      customerDetails={invoiceFromData.customerDetails}
    />

    {/* Product Items */}
    <table>
      <thead>
        <tr>
          <th>#</th>
          <th>Product</th>
          <th>MRP</th>
          <th>Rate</th>
          <th>Discount</th>
          <th>Quantity</th>
          <th>GST Rate</th>
          <th>GST Amount</th>
          <th>Subtotal</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        {invoiceFromData.items.map((item, index) => (
          <ProductRow
            key={index}
            index={index}
            item={item}
            options={productOptions}
            onProductChange={handleProductChange}
            onItemChange={handleItemChange}
          />
        ))}
      </tbody>
    </table>
    <button type="button" onClick={addItem}>Add Item</button>
    <button type="submit">Save Invoice</button>
  </form>
);

export default InvoiceForm;
