import React from 'react';
import Select from 'react-select';

const ProductRow = ({ index, item, options, onProductChange, onItemChange }) => (
  <tr>
    <td>{index + 1}</td>
    <td>
      <Select
        options={options}
        value={options.find((option) => option.value === item.product_id)}
        onChange={(selectedOption) => onProductChange(index, selectedOption)}
      />
    </td>
    <td>{item.mrp}</td>
    <td>
      <input
        type="number"
        value={item.rate || ''}
        onChange={(e) => onItemChange(index, 'rate', Number(e.target.value))}
      />
    </td>
    <td>
      <input
        type="number"
        value={item.discount || ''}
        onChange={(e) => onItemChange(index, 'discount', Number(e.target.value))}
      />
    </td>
    <td>
      <input
        type="number"
        value={item.quantity || ''}
        onChange={(e) => onItemChange(index, 'quantity', Number(e.target.value))}
      />
    </td>
    <td>{item.gst_rate}</td>
    <td>{item.tax_amount}</td>
    <td>{item.subtotal}</td>
    <td>{item.total}</td>
  </tr>
);

export default ProductRow;
