import React from 'react';
import Select from 'react-select';

const CustomerSelect = ({ options, value, onChange, customerDetails }) => (
  <div>
    <Select options={options} value={value} onChange={onChange} />
    <p>GSTIN: {customerDetails.gstin}</p>
    <p>Phone: {customerDetails.phone}</p>
    <p>Email: {customerDetails.email}</p>
    <p>Address: {customerDetails.address}</p>
  </div>
);

export default CustomerSelect;
