import React, { useState, useEffect } from 'react';
import Select from 'react-select';

const CustomerDropdown = () => {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  // Fetch customers from the API
  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await fetch('https://standskills.co.in/riddhimaagency/public/api/customers');
        if (!response.ok) {
          throw new Error('Failed to fetch customers');
        }
        const data = await response.json();
        setCustomers(data);  // Assuming the API returns the customer data directly in the response body
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCustomers();
  }, []);

  // Format data for react-select
  const customerOptions = customers.map(customer => ({
    value: customer.id,   // Use the appropriate field for unique value
    label: customer.name  // Use the appropriate field for the label
  }));

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }


  // Handle the selection of a customer
  const handleCustomerChange = (selectedOption) => {
    const customer = customers.find(p => p.id === selectedOption.value);
    setSelectedCustomer(customer);  // Store the selected customer's details
  };
  return (
    <>
      <Select 
        options={customerOptions}
        onChange={handleCustomerChange}  // Handle customer selection
      />
      
      {selectedCustomer && (
        <div>
          <h4>Customer Details</h4>
          <p><strong>Name:</strong> {selectedCustomer.name}</p>
          <p><strong>Phone:</strong> {selectedCustomer.phone}</p>
          <p><strong>Email:</strong> {selectedCustomer.email}</p>
          <p><strong>Gstin:</strong> {selectedCustomer.gstin}</p>
        </div>
      )}
    </>
  );
};

export default CustomerDropdown;
