import React, { useState } from "react";
import PaginatedList from "./PaginatedList";
import "bootstrap-icons/font/bootstrap-icons.css";

const GstRateList = () => {
  // Setting GstRate Form data
  const [gstRateFromData, setGstRateFromData] = useState({
    gst_rate: "",
  });

  // Validation errors state
  const [errors, setErrors] = useState({});

  // Message for success or error feedback
  const [message, setMessage] = useState({ text: "", type: "" });

  // Handle input change
  const handleGstRateFormChange = (e) => {
    const { name, value } = e.target;
    setGstRateFromData({
      ...gstRateFromData,
      [name]: value,
    });
    // Clear error for the field
    setErrors({ ...errors, [name]: "" });
  };

  // Validate form
  const validateForm = () => {
    const newErrors = {};
    if (!gstRateFromData.gst_rate) newErrors.gst_rate = "GST Rate is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle Add GstRate
  const handleAddGstRate = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const response = await fetch(
        "https://standskills.co.in/riddhimaagency/public/api/gst-rates",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(gstRateFromData),
        }
      );

      if (response.ok) {
        setMessage({ text: "GST Rate added successfully!", type: "success" });
        setGstRateFromData({ gst_rate: "" });
      } else {
        const errorData = await response.json();
        setMessage({
          text: `Failed to add GST Rate: ${
            errorData.message || "Unknown error"
          }`,
          type: "error",
        });
      }
    } catch (error) {
      setMessage({
        text: "Error adding GST Rate. Please try again later.",
        type: "error",
      });
      console.error("Error adding GST Rate:", error);
    }
  };
// Edit
const [selectedItem, setSelectedItem] = useState(null);
const handleGetGstRate = (item) => {
    setSelectedItem(item);
    setGstRateFromData({
      ...gstRateFromData,
      gst_rate: item.gst_rate || "",
      id: item.id || "",
    });
}

  const columns = [
    
    { key: "id", label: "Id" },
    { key: "gst_rate", label: "GST Rate" },
    {
      key: "created_at",
      label: "Action",
      render: (item) => (
          <div className="action-buttons">
          {/* <button className="btn btn-success btn-sm me-2"><i class="bi bi-plus-circle"></i></button> */}
          <button className="btn btn-warning btn-sm me-2"  onClick={() => handleGetGstRate(item)}><i class="bi bi-pencil-square"></i></button>
          <button className="btn btn-danger btn-sm me-2"><i class="bi bi-trash3-fill"></i></button>
          </div>
      ),
    },
  ];

  return (
    <>
      <PaginatedList
        fetchUrl="https://standskills.co.in/riddhimaagency/public/api/gst-rates"
        columns={columns}
        title="GST Rate List"
      />
      <div className="drawer bg-light border-left position-fixed end-0 top-0 h-100 p-4">
        {message.text && (
          <div
            className={`alert ${
              message.type === "success" ? "alert-success" : "alert-danger"
            }`}
            role="alert"
          >
            {message.text}
          </div>
        )}
        <form onSubmit={handleAddGstRate}>
          <div className="mb-3">
            <label htmlFor="gst_rate" className="form-label">
              GST Rate
            </label>
            <input
              type="text"
              name="gst_rate"
              className={`form-control ${errors.gst_rate ? "is-invalid" : ""}`}
              value={gstRateFromData.gst_rate}
              onChange={handleGstRateFormChange}
            />
            {errors.gst_rate && (
              <div className="invalid-feedback">{errors.gst_rate}</div>
            )}
          </div>
          <button type="submit" className="btn btn-primary">
            Save & Print
          </button>
        </form>
      </div>
    </>
  );
};

export default GstRateList;
