import React, { useState } from "react";
import PaginatedList from "./PaginatedList";

const CategoryList = () => {
  // Setting Category Form data
  const [categoryFromData, setCategoryFromData] = useState({
    name: "",
  });

  // Errors state for validation
  const [errors, setErrors] = useState({});

  // Handle input change
  const handleCategoryFormChange = (e) => {
    const { name, value } = e.target;
    setCategoryFromData({
      ...categoryFromData,
      [name]: value,
    });
    setErrors({ ...errors, [name]: "" }); // Clear error for the field
  };

  // Validate form
  const validateForm = () => {
    const newErrors = {};
    if (!categoryFromData.name) newErrors.name = "Category Name is required";
    return newErrors;
  };

  // Handle Add Category
  const handleAddCategory = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const response = await fetch(
        "https://standskills.co.in/riddhimaagency/public/api/categories",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(categoryFromData),
        }
      );

      if (response.ok) {
        alert("Category added successfully!");
        setCategoryFromData({
          name: "",
        });
        setErrors({});
      } else {
        alert("Failed to add category.");
      }
    } catch (error) {
      console.error("Error adding category:", error);
    }
  };

//Edit Functions Here 
//const [showCatgeoryData, setCatgeoryData] = useState(false);
const [selectedItem, setSelectedItem] = useState(null);
const handleGetCategory = (item) => {
    setSelectedItem(item);
    setCategoryFromData({
      ...categoryFromData,
      name: item.name || "",
      id: item.id || "",
    });
}
  const [selectedCategory, setSelectedCategory] = useState(null);
  return (
    <>
      <PaginatedList
        fetchUrl="https://standskills.co.in/riddhimaagency/public/api/categories"
        columns={[
          { key: "id", label: "Id" },
          { key: "name", label: "Category Name" },
          {
            key: "created_at",
            label: "Action",
            render: (item) => (
                <div className="action-buttons">
                {/* <button className="btn btn-success btn-sm me-2"><i class="bi bi-plus-circle"></i></button> */}
                <button className="btn btn-warning btn-sm me-2"  onClick={() => handleGetCategory(item)}><i class="bi bi-pencil-square"></i></button>
                <button className="btn btn-danger btn-sm me-2"><i class="bi bi-trash3-fill"></i></button>
                </div>
            ),
            },
        ]}
        title="Category List"
      />
      <div className="drawer bg-light border-left position-fixed end-0 top-0 h-100 p-4">
        <form onSubmit={handleAddCategory}>
          {[
            { name: "name", label: "Category Name" },
          ].map((field) => (
            <div key={field.name} className="mb-3">
              <label htmlFor={field.name} className="form-label">
                {field.label}
              </label>
              <input
                type="text"
                name={field.name}
                className={`form-control ${
                  errors[field.name] ? "is-invalid" : ""
                }`}
                onChange={handleCategoryFormChange}
                value={categoryFromData[field.name]}
              />
              {errors[field.name] && (
                <div className="invalid-feedback">{errors[field.name]}</div>
              )}
            </div>
          ))}
          <button type="submit" className="btn btn-primary">
            Save & Print
          </button>
        </form>
      </div>
    </>
  );
};

export default CategoryList;
